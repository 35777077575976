<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <v-col>
          <h2>Trial Balance</h2>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Search"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex">
          <v-select
            class="ml-5"
            v-model="form.month"
            label="Months"
            :items="months"
            item-text="text"
            item-value="value"
            prepend-inner-icon="mdi-calendar"
          ></v-select>
          <v-select
            v-model="form.year"
            class="ml-5"
            label="Years"
            :items="years"
            prepend-inner-icon="mdi-calendar"
          ></v-select>

          <v-btn
            small
            class="ml-2 mt-3"
            color="primary"
            @click="get_trial_balance()"
            >Search</v-btn
          ></v-col
        >
      </v-row>

      <v-data-table :items="trial_balance" :headers="headers" :search="search">
        <template v-slot:item.debit_total="{ item }">
          {{ item.debit_total.toLocaleString() }}
        </template>
        <template v-slot:item.credit_total="{ item }">
          {{ item.credit_total.toLocaleString() }}
        </template>
        <template v-slot:item.balance_total="{ item }">
          {{ item.balance_total.toLocaleString() }}
        </template>
      </v-data-table>
      <h2 class="mt-3">
        Total Debit:
        <span class="success--text">{{ totals.debit.toLocaleString() }}</span> |
        Total Credit:
        <span class="primary--text"> {{ totals.credit.toLocaleString() }}</span>
      </h2>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      totals: { debit: 0, credit: 0 },
      trial_balance: [],
      form: { month: "", year: "" },
      loading: false,
      months: [
        { text: "January", value: "01" },
        { text: "Febuary", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: ["2024", "2025", "2026", "2027", "2028"],
      headers: [
        { text: "ID", value: "id" },
        { text: "Account Title", value: "account_title" },
        { text: "Debit", value: "debit_total" },
        { text: "Credit", value: "credit_total" },
        { text: "Balance", value: "balance_total" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    date_today() {
      return moment().format("MMM DD, Y");
    },
    parse_amount(amount) {
      let x = amount.toLocaleString();
      // console.log(x);
      return x;
    },
  },
  created() {
    this.form.month = moment().format("MM");
    this.form.year = moment().format("Y");
    this.get_trial_balance();
  },
  methods: {
    async get_trial_balance() {
      this.loading = true;
      let response = await axios
        .get(
          "/get_trial_balance/" +
            this.form.month +
            "/" +
            this.form.year +
            "/" +
            this.user.branch_id
        )
        .catch((err) => {
          this.loading = false;
          return alert(err);
        });
      this.trial_balance = response.data;
      this.loading = false;

      let debit_total = 0;
      let credit_total = 0;
      response.data.forEach((t) => {
        debit_total += t.debit_total;
        credit_total += t.credit_total;
      });
      this.totals.debit = debit_total;
      this.totals.credit = credit_total;
    },
  },
};
</script>

<style></style>
