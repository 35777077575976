<template>
  <v-container class="mt-16">
    <div class="pl-10 pr-10">
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Journal Entries</h2>

          <v-spacer></v-spacer>

          <add-journal-entry-modal />
          <v-btn color="success" text @click="refresh" :loading="loading"
            ><v-icon class="mr-2">mdi-refresh</v-icon>Refresh</v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-select
                label="Month"
                :items="months"
                item-text="text"
                item-value="value"
                v-model="form.month"
                class="mr-5"
              ></v-select>

              <v-select
                label="Year"
                :items="years"
                v-model="form.year"
              ></v-select>
              <v-btn
                color="pink"
                class="mr-2 white--text"
                rounded
                @click="print('journal_entries')"
                >Journal Entry</v-btn
              >
              <v-btn
                color="success"
                class="mr-2"
                rounded
                @click="print('trial_balance')"
                >Trial Balance</v-btn
              >
              <v-btn
                color="primary"
                class="mr-2"
                rounded
                @click="print('income_statement')"
                >Income Statement</v-btn
              >
              <v-btn
                color="yellow"
                class="mr-2"
                rounded
                @click="print('balance_sheet')"
                >Balance Sheet</v-btn
              >
            </v-col>
          </v-row>
          <v-text-field label="Search" v-model="journal_search"></v-text-field>
          <v-divider></v-divider>

          <v-data-table
            :items="journal_entries"
            :headers="journal_headers"
            :search="journal_search"
          >
            <!-- for debit and credit entries -->
            <template v-slot:item.credit="{ item }">
              <span v-if="!item.is_debit">{{
                item.amount.toLocaleString()
              }}</span>
            </template>
            <template v-slot:item.debit="{ item }">
              <span v-if="item.is_debit">{{
                item.amount.toLocaleString()
              }}</span>
            </template>

            <!-- colors of account title -->
            <template v-slot:item.account_title="{ item }">
              <v-chip
                color="success"
                v-if="item.chart_of_account.account_title == 'Cash'"
                >{{ item.chart_of_account.account_title }}</v-chip
              >

              <v-chip
                color="orange"
                v-else-if="
                  item.chart_of_account.account_title == 'Accounts Receivables'
                "
                >{{ item.chart_of_account.account_title }}</v-chip
              >
              <v-chip
                color="yellow"
                v-else-if="
                  item.chart_of_account.account_title == 'Service Revenue'
                "
                >{{ item.chart_of_account.account_title }}</v-chip
              >

              <v-chip
                color="primary"
                v-else-if="
                  item.chart_of_account.account_title == 'Sales/Item Sales'
                "
                >{{ item.chart_of_account.account_title }}</v-chip
              >

              <v-chip
                color="primary"
                v-else-if="item.chart_of_account.account_title == 'Gcash'"
                >{{ item.chart_of_account.account_title }}</v-chip
              >

              <v-chip color="grey" v-else>
                {{ item.chart_of_account.account_title }}
              </v-chip>
            </template>
            <!-- for invoice id -->
            <template v-slot:item.invoice_id="{ item }">
              <v-chip>{{ item.invoice_id }}</v-chip>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                small
                icon
                color="red"
                @click="del(item)"
                :loading="loading"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>

          <h2>
            Total Debit:
            <span class="success--text">{{
              total_debit_credit.debit.toLocaleString()
            }}</span>
            | Total Credit:
            <span class="primary--text">
              {{ total_debit_credit.credit.toLocaleString() }}</span
            >
          </h2>
        </v-card-text>
      </v-card>

      <!-- general ledger -->
      <v-divider class="mb-4 mt-4"></v-divider>
      <v-row>
        <v-col>
          <trial-balance />
          <chart-of-accounts />
        </v-col>
        <!-- <v-col><income-statement-card /></v-col> -->
      </v-row>
      <v-divider class="mb-4 mt-4"></v-divider>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
// import IncomeStatementCard from "../components/AccountingComponents/incomeStatementCard.vue";
import TrialBalance from "../components/AccountingComponents/trialBalance.vue";
import AddJournalEntryModal from "../components/addJournalEntryModal.vue";
import ChartOfAccounts from "./ChartOfAccounts.vue";
export default {
  components: {
    TrialBalance,
    // IncomeStatementCard,
    AddJournalEntryModal,
    ChartOfAccounts,
  },
  data() {
    return {
      journal_search: "",
      form: {
        month: "",
        year: "",
      },
      months: [
        { text: "January", value: "01" },
        { text: "Febuary", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: ["2024", "2025", "2026", "2027", "2028"],
      loading: false,
      journal_headers: [
        { text: "Action", value: "action" },
        { text: "Journal ID", value: "id" },

        { text: "Account Title", value: "chart_of_account.account_title" },
        { text: "Debit", value: "debit" },
        { text: "Credit", value: "credit" },
        { text: "Date", value: "created_at" },
        { text: "Remarks", value: "remarks" },
      ],
    };
  },
  methods: {
    print(form) {
      console.log(form);
      // check if empty
      if (this.form.month == "" || this.form.year == "")
        return alert("Please select date first");
      var url =
        this.default_server_url +
        "/print_report/" +
        form +
        "/" +
        this.form.month +
        "/" +
        this.form.year +
        "/" +
        this.user.branch_id;
      window.open(url);
    },
    del(item) {
      // return item;
      this.loading = true;
      let x = window.confirm("Are you sure you want to delete this item?");
      if (x) {
        this.delete_journal_entry(item).then(() => {
          alert("Successfully deleted an item");
        });
      }
      this.loading = false;
    },
    ...mapActions({
      get_journal_entries: "journal_entry/get_journal_entries",
      delete_journal_entry: "journal_entry/delete_journal_entry",
    }),
    async refresh() {
      this.loading = true;
      await this.get_journal_entries();
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      journal_entries: "journal_entry/journal_entries",
      default_server_url: "auth/default_server_url",
      user: "auth/user",
    }),

    total_debit_credit() {
      return this.journal_entries.reduce(
        (totals, entry) => {
          if (entry.is_debit) {
            totals.debit += entry.amount;
          } else {
            totals.credit += entry.amount;
          }
          return totals;
        },
        { credit: 0, debit: 0 }
      );
    },
  },
  created() {
    let month = moment().format("MM");
    let year = moment().format("YYYY");
    this.form.month = month;
    this.form.year = year;
    console.log(month, year);
    console.log(this.form);
    if (this.journal_entries.length <= 0) {
      this.get_journal_entries();
    }
  },
};
</script>

<style></style>
