<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Journal Entry</v-card-title>
        <v-card-text>
          <v-select
            label="Chart of Account"
            :items="chart_of_accounts"
            v-model="form.chart_of_account_id"
            item-text="account_title"
            item-value="id"
          ></v-select>
          <v-radio-group v-model="form.is_debit">
            <v-radio label="Debit" value="1"></v-radio>
            <v-radio label="Credit" value="0"></v-radio>
          </v-radio-group>
          <v-text-field label="Amount" v-model="form.amount"></v-text-field>
          <v-textarea label="Remarks" v-model="form.remarks"></v-textarea>

          <div class="d-flex">
            <p class="mr-2 mt-4">Date</p>
            <input type="date" v-model="form.date" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        chart_of_account_id: "",
        is_debit: "",
        date: "",
        remarks: "",
        amount: "",
      },
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  methods: {
    ...mapActions({
      add_journal_entry: "journal_entry/add_journal_entry",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to confirm?");
      if (x) {
        this.add_journal_entry(this.form).then(() => {
          alert("Insert Successful");
        });
      }
      this.loading = false;
    },
  },
  created() {
    this.form.date = moment().format("Y-MM-DD");
  },
};
</script>

<style lang="scss" scoped></style>
