var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-16"},[_c('div',{staticClass:"pl-10 pr-10"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex"},[_c('h2',[_vm._v("Journal Entries")]),_c('v-spacer'),_c('add-journal-entry-modal'),_c('v-btn',{attrs:{"color":"success","text":"","loading":_vm.loading},on:{"click":_vm.refresh}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-refresh")]),_vm._v("Refresh")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-select',{staticClass:"mr-5",attrs:{"label":"Month","items":_vm.months,"item-text":"text","item-value":"value"},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}}),_c('v-select',{attrs:{"label":"Year","items":_vm.years},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),_c('v-btn',{staticClass:"mr-2 white--text",attrs:{"color":"pink","rounded":""},on:{"click":function($event){return _vm.print('journal_entries')}}},[_vm._v("Journal Entry")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","rounded":""},on:{"click":function($event){return _vm.print('trial_balance')}}},[_vm._v("Trial Balance")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.print('income_statement')}}},[_vm._v("Income Statement")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"yellow","rounded":""},on:{"click":function($event){return _vm.print('balance_sheet')}}},[_vm._v("Balance Sheet")])],1)],1),_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.journal_search),callback:function ($$v) {_vm.journal_search=$$v},expression:"journal_search"}}),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.journal_entries,"headers":_vm.journal_headers,"search":_vm.journal_search},scopedSlots:_vm._u([{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(!item.is_debit)?_c('span',[_vm._v(_vm._s(item.amount.toLocaleString()))]):_vm._e()]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.is_debit)?_c('span',[_vm._v(_vm._s(item.amount.toLocaleString()))]):_vm._e()]}},{key:"item.account_title",fn:function(ref){
var item = ref.item;
return [(item.chart_of_account.account_title == 'Cash')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(
                item.chart_of_account.account_title == 'Accounts Receivables'
              )?_c('v-chip',{attrs:{"color":"orange"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(
                item.chart_of_account.account_title == 'Service Revenue'
              )?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(
                item.chart_of_account.account_title == 'Sales/Item Sales'
              )?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):(item.chart_of_account.account_title == 'Gcash')?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.chart_of_account.account_title))]):_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(item.chart_of_account.account_title)+" ")])]}},{key:"item.invoice_id",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.invoice_id))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.del(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('h2',[_vm._v(" Total Debit: "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.total_debit_credit.debit.toLocaleString()))]),_vm._v(" | Total Credit: "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.total_debit_credit.credit.toLocaleString()))])])],1)],1),_c('v-divider',{staticClass:"mb-4 mt-4"}),_c('v-row',[_c('v-col',[_c('trial-balance'),_c('chart-of-accounts')],1)],1),_c('v-divider',{staticClass:"mb-4 mt-4"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }